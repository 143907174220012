import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  json,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import mobileCSS from "./styles/mobile.css?url";
import { useEffect, useState } from "react";
import { trackPageView } from "./analytics.client";

import AppContextProvider from "~/utils/app-content-provider";
import { SnackbarProvider, useSnackbar } from "~/components";
import { commitSession, getSession } from "./.server/session";

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: mobileCSS }];
};

export const meta: MetaFunction = () => {
  return [{ title: "FlirtyBum" }];
};

export function Layout({ children }: { children: React.ReactNode }) {
  // const data = useLoaderData<typeof loader>() ||{ theme: "light" };
  const theme =  "light";
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true); // Set `hydrated` to true after client-side rendering
  }, []);

  useEffect(() => {
    console.log("Theme:", theme);
    console.log("Hydrated:", hydrated);
  }, [theme, hydrated]);

  // The `hydrated` class is added directly to avoid mismatch
  const htmlClassName = `${theme} hydrated`.trim();


  return (
    <html lang="en" className={htmlClassName}>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, interactive-widget=resizes-content, viewport-fit=cover"
        />
        <meta
          httpEquiv="Delegate-CH"
          content="sec-ch-ua https://ads.trafficjunky.net; sec-ch-ua-arch https://ads.trafficjunky.net; sec-ch-ua-full-version-list https://ads.trafficjunky.net; sec-ch-ua-mobile https://ads.trafficjunky.net; sec-ch-ua-model https://ads.trafficjunky.net; sec-ch-ua-platform https://ads.trafficjunky.net; sec-ch-ua-platform-version https://ads.trafficjunky.net;"
        />
        <Meta />
        <Links />
      </head>
      <body className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed">
        <div id="root" className="d-flex flex-column flex-root dark-bg">
          {children}
          <ScrollRestoration />
        </div>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
          crossOrigin="anonymous"
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-S205G2B9BS"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S205G2B9BS');
          `,
          }}
        />
        <Scripts />
        <img
          src="https://ads.trafficjunky.net/rt?action=list&type=add&id=click&context=flirtybum&cookiename=flirtybum&maxcookiecount=10"
          style={{ height: 0, width: 0 }}
        />
      </body>
    </html>
  );
}

//stylise error boundary for UI purposes
export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  return <div>Something went wrong</div>;
};

export default function App() {
  
  return (
    <AppContextProvider>
      <SnackbarProvider>
        <Outlet />
      </SnackbarProvider>
    </AppContextProvider>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {

  const session = await getSession(request.headers.get("Cookie"));
  const theme = session.get("theme") || "light"; // Fallback to 'light' if theme is missing

  const url = new URL(request.url);
  if (
    url.pathname !== "/maintenance" &&
    process.env.MAINTENANCE_MODE === "true"
  ) {
    throw redirect("/maintenance");
  } else if (
    url.pathname === "/maintenance" &&
    process.env.MAINTENANCE_MODE !== "true"
  ) {
    throw redirect("/landing-pub");
  }
  return json({ theme });
}
